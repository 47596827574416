.product {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	margin: 10px;
	padding: 20px;
	width: 100%;
	min-width: 100px;

	background-color: #fff;
	z-index: 1;
}

.product__rating {
	display: flex;
}

.product__info {
	width: 100%;
	height: 200px;
	margin-bottom: 25px;
}

.product__price {
	margin-top: 5px;
}
.button {
	z-index: 10000;
	background: #fec14b;
	border: 1px solid;
	margin-top: -50px;
	border-color: #a88734;
	color: #111;
	cursor: pointer;
	border-radius: 5px;
}
.product > button:hover {
	background: #fec14b;
	border: 3px solid;
	border-color: green;
	border-radius: 7px;
}
.product > button:active {
	background: grey;
	border: 3px solid;
	border-color: green;
}

.show {
	display: none;
}

@media screen and (max-width: 600px) {
	.hidden {
		display: none;
	}

	.button {
		margin-top: 50px;
	}

	.show {
		display: inline;
	}
}
