.subtotal {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 300px;
	height: 150px;
	padding: 20px;
	background-color: #f3f3f3;
	border: 1px solid #ddd;
	border-radius: 3px;
}

.subtotal__gift {
	display: flex;
	align-items: center;
}

.subtotal > input {
	margin-right: 5px;
}

.subtotal > button {
	background-color: #f0c14b;
	border-radius: 2px;
	width: 100%;
	height: 30px;
	border: 1px solid;
	margin-top: 10px;
	border-color: #a88734;
	color: #111;
	cursor: pointer;
}
