.checkoutProduct {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
}

.heckoutProduct__info {
	padding-left: 20px;
}

.checkoutProduct__info > button {
	background: #f0c14b;
	border: 1px solid;
	margin-top: 10px;
	border-color: #a88734;
	color: #111;
	cursor: pointer;
}
.checkoutProduct__info > button:hover {
	background: #fec14b;
	border: 3px solid;
	margin-top: px;
	border-color: green;
	border-radius: 7px;
}
.checkoutProduct__info > button:active {
	background: grey;
	border: 3px solid;
	margin-top: px;
	border-color: green;
}
.checkoutProduct__image {
	object-fit: contain;
	width: 180px;
	height: 180px;
}

.checkoutProduct__rating {
	display: flex;
}

.checkoutProduct__title {
	font-size: 17px;
	font-weight: 800;
}
