.login__logo {
	margin-top: 20px;
	margin-bottom: 20px;
	object-fit: contain;
	width: 100px;
	margin-left: auto;
	margin-right: auto;
}

.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	background-color: #fff;
}

.login__container {
	width: 300px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	border: 1px solid lightgray;
	padding: 20px;
}

.login__container > h1 {
	font-weight: 500;
	margin-bottom: 20px;
}

.login__container > form > h5 {
	margin-bottom: 5px;
}

.login__container > form > input {
	height: 30px;
	margin-bottom: 10px;
	background-color: #fff;
	width: 98%;
}

.login__container > p {
	margin-top: 15px;
	font-size: 12px;
}

.login__signInButton {
	background-color: #f0c14b;
	border-radius: 2px;
	width: 100%;
	height: 30px;
	border: 1px solid;
	margin-top: 10px;
	border-color: #a88734 #9c7e31;
}

.login__signInButton:hover {
	border-color: green;
	cursor: pointer;
}

.login__signInButton:active {
	background-color: lightgray;
}

.login__registerButton {
	border-radius: 2px;
	width: 100%;
	height: 30px;
	border: 1px solid;
	margin-top: 10px;
	border-color: darkgray;
}

.login__registerButton:hover {
	background-color: wheat;
	cursor: pointer;
}

.login__registerButton:active {
	background-color: lightgray;
}
