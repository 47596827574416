.header {
	height: 60px;
	display: flex;
	align-items: center;
	background-color: #fff; /*#131921; */
	position: sticky;
	top: 0;
	z-index: 100;
}

.header__logo {
	width: 100px;
	object-fit: contain;
	margin: 0 20px;
	margin-top: 25px;
}

.header__search {
	display: flex;
	flex: 1;
	align-items: center;
	border-radius: 24px;
}

.header__searchInput {
	height: 12px;
	padding: 10px;

	width: 100%;
}

.header__searchIcon {
	padding: 5px;
	height: 22px;
	background-color: #cd9042;
	cursor: pointer;
}

.header__nav {
	display: flex;
	justify-content: space-evenly;
}

.header__option {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-right: 10px;
	color: black;
}

.header__optionLineOne {
	font-size: 10px;
	cursor: pointer;
}

.header__optionLineTwo {
	font-size: 13px;
	font-weight: 800;
	cursor: pointer;
}

.header__optionBasket {
	display: flex;
	align-items: center;
	color: rgb(226, 29, 104);
}

.header__basketCount {
	margin-left: 10px;
	margin-right: 10px;
}

@media screen and (max-width: 600px) {
	.prime {
		display: none;
	}
}
