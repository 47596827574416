.home__image {
	width: 100%;
	z-index: -1;
	height: 1000px;
	margin-top: -100px;
	margin-bottom: -600px;
}

.home {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 1500px;
	background-color: grey;
}

.home__row {
	display: flex;
	z-index: 1;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
}
.sliderimg {
	max-height: 200px;
	width: 100%;
	object-fit: contain;
	margin-bottom: 15px;
	/*color: rgba(59, 29, 9, 0.877)*/
}
